import { createUILibraryComponent } from './component';

export const SiteContent = createUILibraryComponent({
  displayName: 'SiteContent',
  defaultComponent: 'div',
  defaultProps: {
    className: 'site-content',
  },
});

export const SiteMain = createUILibraryComponent({
  displayName: 'SiteMain',
  defaultComponent: 'main',
  defaultProps: {
    className: 'site-main',
  },
});

export const MainContentStart = createUILibraryComponent({
  displayName: 'MainContentStart',
  defaultComponent: 'span',
  defaultProps: {
    id: 'maincontentstart',
  },
});
