import classNames from 'classnames';
import * as React from 'react';

import { makeSpreadProps, useVisageProps } from './headless-ui';
import { ForwardRefComponent, UILibraryComponentProps } from './types';

type FlexibleComponentProps<P extends {}, C extends React.ElementType<any>> = P & {
  component: C;
} & React.ComponentPropsWithoutRef<C>;

export function FlexibleComponent<P extends {}, C extends React.ElementType<any>>(
  props: FlexibleComponentProps<P, C>,
) {
  const { component: Comp, ...otherProps } = props;

  return <Comp {...otherProps} />;
}
FlexibleComponent.displayName = 'FlexibleComponent';

/**
 * Creates a UI Library component with no frills. Just the shared functionality and the basic functionality of the HTML tag that's rendered
 *
 * @param defaultComponent - the component which should be rendered if the `as` prop is not used
 * @param defaultProps - a set of props which should be added to each instance of the comp by default
 */
export function createUILibraryComponent<
  R extends HTMLElement,
  T extends React.ElementType
>(configuration: {
  defaultComponent: T;
  defaultProps?: Partial<UILibraryComponentProps>;
  displayName: string;
}): ForwardRefComponent<R, UILibraryComponentProps> {
  const { defaultComponent, defaultProps = {}, displayName } = configuration;

  const UILibraryComponent = React.forwardRef<R, UILibraryComponentProps>((customProps, ref) => {
    // generally, all defaultProps can be overridden by custom props
    // but, for className and style, we want to _merge_ the default and custom values rather than choosing one

    const {
      className: defaultClassName,
      style: defaultStyle = {},
      ...otherDefaultProps
    } = defaultProps;

    const {
      className: customClassName,
      style: customStyle = {},
      ...otherCustomProps
    } = customProps;

    const mergedProps = {
      className:
        defaultClassName !== undefined || customClassName !== undefined
          ? classNames(defaultClassName, customClassName)
          : undefined,
      style: { ...defaultStyle, ...customStyle },
      ...otherDefaultProps,
      ...otherCustomProps,
    };

    const { component: Comp = defaultComponent, rendered = true, ...otherProps } = makeSpreadProps(
      useVisageProps(mergedProps),
    );

    if (!rendered) {
      return null;
    }

    return (
      <Comp ref={ref} {...otherProps} />
    );
  });

  UILibraryComponent.displayName = displayName;

  return UILibraryComponent;
}

export const UILibraryComponent = createUILibraryComponent({
  displayName: 'VisageComponent',
  defaultComponent: 'div',
});
