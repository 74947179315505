import { Link } from 'gatsby';
import React from 'react';
import { AnimatedHeader } from '../components/AnimatedHeader';
import { Avatar } from '../components/Avatar';
import { FlexBox } from '../components/FlexBox';
import { Grid } from '../components/Grid';
import { Hidden } from '../components/Hidden';
import { Navigation } from '../components/Navigation';
import {
  responsive,
  ScreenClassProvider,
} from '../components/responsiveSystem';
import { SocialMediaLinks } from '../components/SocialMediaLinks';
import '../styles/base.scss';
import styles from './Layout.module.scss';

const rootPath = `${__PATH_PREFIX__}/`;

type LayoutProps = {
  location: {
    pathname: string;
  };
};

const ResponsiveGrid = responsive(Grid);

export const Layout: React.FC<LayoutProps> = ({ children, location }) => {
  return (
    <ScreenClassProvider>
      <div className={styles.layout}>
        <div className={styles.siteContainer}>
          <ResponsiveGrid
            component="header"
            gridTemplateColumns="1fr auto"
            alignItems="center"
            paddingX={3}
            sm={{
              paddingX: 6,
            }}
          >
            <FlexBox alignItems="center">
              <Hidden sm xs>
                <Link to="/">
                  <Avatar marginRight={4} />
                </Link>
              </Hidden>
              <AnimatedHeader animate={location.pathname === rootPath} />
            </FlexBox>
            <Navigation />
          </ResponsiveGrid>
          <main className={styles.main}>{children}</main>
        </div>
        <footer>
          <SocialMediaLinks />
        </footer>
      </div>
    </ScreenClassProvider>
  );
};

export default Layout;
