import classNames from 'classnames';
import * as React from 'react';
import { UILibraryComponent } from '../core';
import { UILibraryComponentProps } from '../core/types';

type HiddenProps = UILibraryComponentProps & {
  /**
   * Whether or not the content should be hidden on extra-small screens
   *
   * @default false
   */
  xs?: boolean;

  /**
   * Whether or not the content should be hidden on small screens
   *
   * @default false
   */
  sm?: boolean;

  /**
   * Whether or not the content should be hidden on medium screens
   *
   * @default false
   */
  md?: boolean;

  /**
   * Whether or not the content should be hidden on large screens
   *
   * @default false
   */
  lg?: boolean;
};

/**
 * A container which hides its contents on certain screen sizes
 */
export const Hidden = React.forwardRef<HTMLElement, HiddenProps>(
  (props, ref) => {
    const {
      xs = false,
      sm = false,
      md = false,
      lg = false,

      className,
      ...otherProps
    } = props;

    return (
      <UILibraryComponent
        component="div"
        ref={ref}
        className={classNames(
          { 'hidden-xs': xs },
          { 'hidden-sm': sm },
          { 'hidden-md': md },
          { 'hidden-lg': lg },
          className
        )}
        {...otherProps}
      />
    );
  }
);

Hidden.displayName = 'Hidden';
