type BreakpointMap = { [screenClass in ScreenClass]: number };

/**
 * Map of Screen Classes to their max-pixel-widths
 */
export const screenClassMaxWidths: BreakpointMap = {
  xs: 767,
  sm: 1023,
  md: 1439,
  lg: Infinity,
};

/**
 * Map of Screen Classes to their max-pixel-widths
 */
export const screenClassMinWidths = {
  xs: 0,
  sm: 768,
  md: 1024,
  lg: 1440,
};

type ScreenClass = 'xs' | 'sm' | 'md' | 'lg';

/**
 * Screen Classes listed from smallest to largest
 */
const screenClasses: ScreenClass[] = ['xs', 'sm', 'md', 'lg'];

/**
 * Returns the Screen Class corresponding to the given width
 *
 * @param windowWidth - the width (in px) of the window/screen
 */
export function getScreenClass(windowWidth: number): ScreenClass {
  for (let i = 0; i < screenClasses.length; ++i) {
    const screenClass = screenClasses[i];
    const maxWidth = screenClassMaxWidths[screenClass];
    const minWidth = screenClassMinWidths[screenClass];

    if (windowWidth >= minWidth && windowWidth <= maxWidth) {
      return screenClass;
    }
  }

  // the loop above should be guaranteed to return a value
  // if we hit this error, we messed up
  throw new Error(`Misconfigured screen classes.`);
}
