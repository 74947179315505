import * as React from 'react';
import { UILibraryComponent } from '../core';
import { UILibraryComponentProps } from '../core/types';

type FlexBoxProps = UILibraryComponentProps &
  Pick<
    React.CSSProperties,
    'flexWrap' | 'flexDirection' | 'alignItems' | 'justifyContent'
  >;

export const FlexBox = React.forwardRef<HTMLElement, FlexBoxProps>(
  (props, ref) => {
    const {
      flexWrap,
      flexDirection,
      alignItems,
      justifyContent,

      style = {},
      ...otherProps
    } = props;

    return (
      <UILibraryComponent
        component="div"
        ref={ref}
        style={{
          display: 'flex',
          flexWrap,
          flexDirection,
          alignItems,
          justifyContent,
          ...style,
        }}
        {...otherProps}
      />
    );
  }
);

FlexBox.displayName = 'FlexBox';
