import classNames from 'classnames';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './ToggleMenu.module.scss';
import { TextButton, TextButtonIcon } from './Button';

interface ToggleMenuContextValue {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleButtonRef?: React.MutableRefObject<HTMLButtonElement>;
  handleCloseMenuAndFocus: () => void;
}

/* eslint-disable @typescript-eslint/no-empty-function */
const ToggleMenuContext = createContext<ToggleMenuContextValue>({
  open: false,
  setOpen: () => {},
  handleCloseMenuAndFocus: () => {},
});
/* eslint-enable @typescript-eslint/no-empty-function */

type ToggleMenuMenuProps = React.HTMLProps<HTMLElement>;

export const ToggleMenuMenu: React.FC<ToggleMenuMenuProps> = (props) => {
  const [open, setOpen] = useState(false);
  const toggleButtonRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const handleCloseMenuAndFocus = useCallback(() => {
    setOpen(false);
    if (toggleButtonRef.current) {
      toggleButtonRef.current.focus();
    }
  }, []);
  const value = useMemo(
    () => ({ open, setOpen, toggleButtonRef, handleCloseMenuAndFocus }),
    [open]
  );
  const { onClick, className, ...rest } = props;
  const classes = classNames(styles.toggleMenu, className);

  /*
   * Ensure menu closes when clicking outside the view
   */
  useEffect(() => {
    window.addEventListener('click', handleCloseMenuAndFocus);
    return () => {
      window.removeEventListener('click', handleCloseMenuAndFocus);
    };
  });

  return (
    <ToggleMenuContext.Provider value={value}>
      <nav
        className={classes}
        data-cy="toggle-menu-nav"
        onClick={(event) => {
          event.persist();
          event.stopPropagation();
          if (onClick) {
            onClick(event);
          }
        }}
        {...rest}
      />
    </ToggleMenuContext.Provider>
  );
};

type ToggleMenuToggleProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const ToggleMenuToggle: React.FC<ToggleMenuToggleProps> = (props) => {
  const { onClick, className, children, ...rest } = props;
  const { open, setOpen, toggleButtonRef } = useContext(ToggleMenuContext);
  const classes = classNames(
    'stylized-select',
    styles.toggleMenuToggle,
    className,
    {
      [styles.toggleMenuToggleOpen]: open
    }
  );

  return (
    <button
      ref={toggleButtonRef}
      className={classes}
      aria-expanded={open}
      onClick={(event) => {
        event.persist();
        setOpen(!open);
        if (onClick) {
          onClick(event);
        }
      }}
      {...rest}
    >
      {/* {children} */}
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

type ToggleMenuListProps = React.HTMLProps<HTMLUListElement>;

export const ToggleMenuList: React.FC<ToggleMenuListProps> = (props) => {
  const { open } = useContext(ToggleMenuContext);
  const { className, ...rest } = props;
  const classes = classNames(styles.toggleMenuList, className);
  const style = {
    '--toggle-state': open ? 'block' : 'none',
  } as React.CSSProperties;
  return (
    <div className={styles.toggleMenuListContainer}>
      <ul className={classes} style={style} {...rest} />
    </div>
  );
};

type ToggleMenuListItemProps = React.HTMLProps<HTMLLIElement>;

export const ToggleMenuListItem: React.FC<ToggleMenuListItemProps> = (
  props
) => {
  const { className, ...rest } = props;
  const classes = classNames(styles.toggleMenuListItem, className);
  return <li className={classes} {...rest} />;
};

interface ToggleMenuLinkProps
  extends React.HTMLProps<HTMLElement>,
    Pick<
      GatsbyLinkProps<{}>,
      | 'activeClassName'
      | 'activeStyle'
      | 'partiallyActive'
      | 'replace'
      | 'innerRef'
    > {
  to?: string;
  component?: React.ElementType;
}

export const ToggleMenuLink: React.FC<ToggleMenuLinkProps> = (props) => {
  const { handleCloseMenuAndFocus } = useContext(ToggleMenuContext);
  const {
    component: Component = GatsbyLink,
    className,
    activeClassName,
    onClick,
    ...rest
  } = props;
  const classes = classNames(styles.toggleMenuLink, className);
  const activeClasses = classNames(activeClassName);
  const componentProps = {
    onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.persist();
      handleCloseMenuAndFocus();
      if (onClick) {
        onClick(event);
      }
    },
    ...rest,
    className: classes,
  };

  if (Component === GatsbyLink) {
    (componentProps as GatsbyLinkProps<{}>).activeClassName = activeClasses;
  }

  return <Component {...componentProps} />;
};
