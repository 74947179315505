import classNames from 'classnames';
import warning from 'warning';

import { HTMLProps } from '../../types';
import { omit } from '../../utils/omit';

type OneThroughTwelve = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type OneThroughSeven = 1 | 2 | 3 | 4 | 5 | 6 | 7;

type PaddingSpacingSize = '0' | 's' | 'm' | 'l' | 'xl' | 0;
type VerticalPaddingSpacingSize = PaddingSpacingSize | OneThroughTwelve;
type HorizontalPaddingSpacingSize = PaddingSpacingSize | OneThroughSeven;

type MarginSpacingSize = '0' | 's' | 'm' | 'l' | 'xl' | 0;
type VerticalMarginSpacingSize = MarginSpacingSize | OneThroughTwelve;
type HorizontalMarginSpacingSize = MarginSpacingSize | OneThroughSeven | 'auto';

export type SpaceableComponentProps = {
  margin?: MarginSpacingSize | OneThroughTwelve;
  marginTop?: VerticalMarginSpacingSize;
  marginRight?: HorizontalMarginSpacingSize;
  marginBottom?: VerticalMarginSpacingSize;
  marginLeft?: HorizontalMarginSpacingSize;
  marginX?: HorizontalMarginSpacingSize;
  marginY?: VerticalMarginSpacingSize;

  m?: MarginSpacingSize | OneThroughTwelve;
  mt?: VerticalMarginSpacingSize;
  mr?: HorizontalMarginSpacingSize;
  mb?: VerticalMarginSpacingSize;
  ml?: HorizontalMarginSpacingSize;
  mx?: HorizontalMarginSpacingSize;
  my?: VerticalMarginSpacingSize;

  padding?: PaddingSpacingSize | OneThroughTwelve;
  paddingTop?: VerticalPaddingSpacingSize;
  paddingRight?: HorizontalPaddingSpacingSize;
  paddingBottom?: VerticalPaddingSpacingSize;
  paddingLeft?: HorizontalPaddingSpacingSize;
  paddingX?: HorizontalPaddingSpacingSize;
  paddingY?: VerticalPaddingSpacingSize;

  p?: PaddingSpacingSize | OneThroughTwelve;
  pt?: VerticalPaddingSpacingSize;
  pr?: HorizontalPaddingSpacingSize;
  pb?: VerticalPaddingSpacingSize;
  pl?: HorizontalPaddingSpacingSize;
  px?: HorizontalPaddingSpacingSize;
  py?: VerticalPaddingSpacingSize;
};

export const spaceableComponentProps: (keyof SpaceableComponentProps)[] = [
  'margin',
  'marginTop',
  'marginRight',
  'marginBottom',
  'marginLeft',
  'marginX',
  'marginY',
  'm',
  'mt',
  'mr',
  'mb',
  'ml',
  'mx',
  'my',
  'padding',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
  'paddingX',
  'paddingY',
  'p',
  'pt',
  'pr',
  'pb',
  'pl',
  'px',
  'py',
];

/**
 * Spacing class names are made up of a root and a suffix e.g. "mb-xl"
 */
type SpacingClassNameRoot =
  | 'm'
  | 'mt'
  | 'mr'
  | 'mb'
  | 'ml'
  | 'mx'
  | 'my'
  | 'p'
  | 'pt'
  | 'pr'
  | 'pb'
  | 'pl'
  | 'px'
  | 'py';
type SpacingClassNameSuffix =
  | '0'
  | 's'
  | 'm'
  | 'l'
  | 'xl'
  | 'auto'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12';

/**
 * Maps a SpacingProp to a SpacingClassNameRoot
 */
const spacingPropNameToClassNameRootMap: {
  [key in keyof SpaceableComponentProps]: SpacingClassNameRoot;
} = {
  margin: 'm',
  marginTop: 'mt',
  marginRight: 'mr',
  marginBottom: 'mb',
  marginLeft: 'ml',
  marginX: 'mx',
  marginY: 'my',
  m: 'm',
  mt: 'mt',
  mr: 'mr',
  mb: 'mb',
  ml: 'ml',
  mx: 'mx',
  my: 'my',

  padding: 'p',
  paddingTop: 'pt',
  paddingRight: 'pr',
  paddingBottom: 'pb',
  paddingLeft: 'pl',
  paddingX: 'px',
  paddingY: 'py',
  p: 'p',
  pt: 'pt',
  pr: 'pr',
  pb: 'pb',
  pl: 'pl',
  px: 'px',
  py: 'py',
};

type AnySpacingSize = MarginSpacingSize | PaddingSpacingSize | OneThroughTwelve | 'auto';

/**
 * Maps a SpacingSize to a SpacingClassNameSuffix
 */
const spacingSizeToClassNameSuffixMap: {
  [key in AnySpacingSize]: SpacingClassNameSuffix;
} = {
  '0': '0',
  auto: 'auto',
  s: 's',
  m: 'm',
  l: 'l',
  xl: 'xl',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12',
};

/**
 * Each SpacingProp has a short-hand and long-hand representation. This associates the pairs so that we can e.g. de-duplicate
 */
const spacingPropPairs: [keyof SpaceableComponentProps, keyof SpaceableComponentProps][] = [
  ['margin', 'm'],
  ['marginTop', 'mt'],
  ['marginRight', 'mr'],
  ['marginBottom', 'mb'],
  ['marginLeft', 'ml'],
  ['marginX', 'mx'],
  ['marginY', 'my'],
  ['padding', 'p'],
  ['paddingTop', 'pt'],
  ['paddingRight', 'pr'],
  ['paddingBottom', 'pb'],
  ['paddingLeft', 'pl'],
  ['paddingX', 'px'],
  ['paddingY', 'py'],
];

export function useSpaceable<P extends HTMLProps & SpaceableComponentProps>(
  props: P,
): Omit<P, keyof SpaceableComponentProps> {
  const classList: string[] = [];

  // make sure that we don't have any redundant props (e.g. defining both `m` and `margin`)
  spacingPropPairs.forEach(([longHandPropName, shortHandPropName]) => {
    const longHandValue = props[longHandPropName];
    const shortHandValue = props[shortHandPropName];

    // if neither the longHand nor the shortHand prop are defined, evacuate
    if (longHandValue === undefined && shortHandValue === undefined) {
      return;
    }

    // we know that at least one of the props (longHand or shortHand) have a defined value
    // let's make sure they're not _both_ defined and throw an error if they are
    warning(
      longHandValue === undefined || shortHandValue === undefined,
      `Spacing error. \`${shortHandPropName}\` is a short-hand notation for \`${longHandPropName}\`, but both have been set. Please choose one or the other.`,
    );

    // 1. grab whichever spacing value is defined
    // we've confirmed (via the warning) that either longHandValue or shortHandValue is defined, so spacingValue will never be undefined
    // 2. we assume that the value passed in is actually valid, but it may be worth validating (although other props don't include validations, they just don't work if you pass invalid data)
    const spacingValue = (longHandValue !== undefined
      ? longHandValue
      : shortHandValue) as AnySpacingSize;

    // get the actual UI Library class name (we arbitrarily look up based on the longHandPropName; shortHandPropName would have worked just as well)
    const classNameRoot = spacingPropNameToClassNameRootMap[longHandPropName];
    const classNameSuffix = spacingSizeToClassNameSuffixMap[spacingValue];

    classList.push(`${classNameRoot}-${classNameSuffix}`);
  });

  return omit(
    {
      ...props,
      className: classNames(props.className, classList),
    },
    spaceableComponentProps,
  );
}
