import classNames from 'classnames';

import { HTMLProps } from '../../types';
import { omit } from '../../utils/omit';

export type VisuallyHidableComponentProps = {
  /**
   * Whether or not the component should be visible to sighted users
   *
   * `visuallyHidden` content will only be "seen" by assistive technologies like screen-readers
   *
   * @default false
   */
  visuallyHidden?: boolean;
};

export const visuallyHidableComponentProps: (keyof VisuallyHidableComponentProps)[] = [
  'visuallyHidden',
];

export function useVisuallyHidable<P extends HTMLProps & VisuallyHidableComponentProps>(
  props: P,
): Omit<P, keyof VisuallyHidableComponentProps> {
  const { visuallyHidden } = props;

  return omit(
    {
      ...props,
      className: classNames(props.className, {
        'visually-hidden': visuallyHidden,
      }),
    },
    visuallyHidableComponentProps,
  );
}
