import * as React from 'react';
import { UILibraryComponent } from '../core';
import { UILibraryComponentProps } from '../core/types';

type GridProps = UILibraryComponentProps<{
  gridTemplateRows?: string;
  gridTemplateColumns?: string;
  alignItems?: string;
  justifyContent?: string;
}>;

export const Grid = React.forwardRef<HTMLElement, GridProps>((props, ref) => {
  const {
    gridTemplateColumns,
    gridTemplateRows,
    alignItems,
    justifyContent,
    style,
    ...otherProps
  } = props;

  return (
    <UILibraryComponent
      component="div"
      ref={ref}
      style={{
        display: 'grid',
        gridTemplateRows,
        gridTemplateColumns,
        alignItems,
        justifyContent,
        ...style,
      }}
      {...otherProps}
    />
  );
});

Grid.displayName = 'Card';
