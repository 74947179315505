import { Location } from '@reach/router';
import { withPrefix } from 'gatsby';
import React from 'react';
import styles from './Navigation.module.scss';
import {
  ToggleMenuListItem as Li,
  ToggleMenuList as List,
  ToggleMenuMenu as Menu,
  ToggleMenuLink as NavLink,
  ToggleMenuToggle as Toggle,
} from './ToggleMenu';

type RouteMap = {
  [key: string]: {
    id: string;
    text: string;
  };
};

const routes: RouteMap = {
  '/': {
    id: 'route-home',
    text: 'Home',
  },
  '/blog': { id: 'route-blog', text: 'Blog' },
  '/presentations': { id: 'route-presentations', text: 'Presentations' },
};

export const Navigation = () => (
  <Location>
    {(props) => {
      const matchingRouteKey =
        Object.keys(routes).find((key) => {
          // withPrefix prefixes the paths with the gatsby --path-prefix
          // value that is only applied in production
          if (key === '/') {
            return props.location.pathname === key;
          }
          return props.location.pathname.startsWith(withPrefix(key));
        }) || '';

      const currentPage = routes[matchingRouteKey] || routes['/'];
      return (
        <Menu>
          <Toggle>{currentPage.text}</Toggle>
          <List>
            {Object.keys(routes).map((to) => (
              <Li key={to}>
                <NavLink to={to} className={styles.link}>
                  {routes[to].text}
                </NavLink>
              </Li>
            ))}
          </List>
        </Menu>
      );
    }}
  </Location>
);
