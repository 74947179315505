import classNames from 'classnames';
import React from 'react';
import styles from './AnimatedHeader.module.scss';
import { Name } from './Name';

type AnimatedHeaderProps = {
  animate?: boolean;
};

export const AnimatedHeader: React.FC<AnimatedHeaderProps> = ({
  animate = true,
}) => (
  <div
    className={classNames([
      styles.animatedHeader,
      { [styles.animated]: animate },
    ])}
  >
    <Name />
  </div>
);
