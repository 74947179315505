import React from 'react';
import styles from './Name.module.scss';

export const Name: React.FC = () => (
  <h1 className={styles.header}>
    Hey,&nbsp;
    <span className={styles.name}>I&apos;m Josh</span>
    <br />
    <span className={styles.tagline}>a fullstack engineer</span>
  </h1>
);
