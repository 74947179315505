import classNames from 'classnames';

import { HTMLProps } from '../../types';
import { omit } from '../../utils/omit';

export type BackgroundColor =
  | 'black'
  | 'white'
  | 'mist'
  | 'translucent-blue'
  | 'light-grey'
  | 'light-blue';

export type BackgroundColorableComponentProps = {
  backgroundColor?: BackgroundColor;

  /**
   * Should the background color not cover the whole element?
   */
  backgroundShort?: boolean;
};

export const backgroundColorableComponentProps: (keyof BackgroundColorableComponentProps)[] = [
  'backgroundColor',
  'backgroundShort',
];

export function useBackgroundColorable<P extends HTMLProps & BackgroundColorableComponentProps>(
  props: P,
): Omit<P, keyof BackgroundColorableComponentProps> {
  const { backgroundColor, backgroundShort } = props;

  return omit(
    {
      ...props,
      className: classNames(
        props.className,
        { [`background-${backgroundColor}`]: backgroundColor !== undefined },
        { 'background-short': backgroundShort },
      ),
    },
    backgroundColorableComponentProps,
  );
}
