import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import { createUILibraryComponent } from '../core';
import { PropsOf } from '../core/types';
import { UILibraryComponentProps } from '../core/types';

const bioQuery = graphql`
  query AvatarQuery {
    site {
      siteMetadata {
        author
      }
    }
    avatar: file(absolutePath: { regex: "/avatar.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fixed(width: 60, height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Img = createUILibraryComponent({
  displayName: 'Image',
  defaultComponent: Image,
});

type AvatarProps = PropsOf<typeof Image> & UILibraryComponentProps;

export const Avatar: React.FC<AvatarProps> = ({ style, ...props }) => {
  const data = useStaticQuery(bioQuery);
  const {
    site: {
      siteMetadata: { author },
    },
    avatar,
  } = data;

  return avatar ? (
    <Img
      fixed={avatar.childImageSharp.fixed}
      alt={author}
      style={{
        borderRadius: 9999,
        ...style,
      }}
      {...props}
    />
  ) : null;
};
