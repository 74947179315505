import { createResponsiveSystem } from 'react-responsive-system';
import { screenClassMaxWidths } from '../core/breakpoints';

export const {
  ScreenClassProvider,
  useScreenClass,
  responsive,
  useResponsiveProps,
} = createResponsiveSystem({
  breakpoints: screenClassMaxWidths,
  defaultScreenClass: 'xs',
  cascadeMode: 'mobile-first',
});
